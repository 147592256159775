import * as React from "react"
import { RiQuestionFill } from "react-icons/ri"
import { Box, Flex, FormLabel, FormLabelProps, Text, Tooltip, useColorModeValue } from "@chakra-ui/react"

import { SanitizedContent } from "components/SanitizedContent"
interface Props extends Omit<FormLabelProps, "children"> {
  name?: string
  label?: React.ReactNode
  subLabel?: string
  helpText?: string
}

export const InputLabel: React.FC<Props> = ({ subLabel, helpText, ...props }) => {
  const [isLabelOpen, setIsLabelOpen] = React.useState(false)
  const subLabelColor = useColorModeValue("gray.400", "gray.300")
  if (!props.label) return null
  return (
    <Flex mb={1} align="flex-end" justifyContent="space-between">
      <FormLabel htmlFor={props.name} mb={0} {...props} fontSize="sm">
        {props.label}
        {subLabel && (
          <Text ml={2} as="span" fontSize="sm" color={subLabelColor}>
            {subLabel}
          </Text>
        )}
      </FormLabel>
      {!!helpText && (
        <Tooltip label={<SanitizedContent content={helpText} />} isOpen={isLabelOpen}>
          <Text
            as="span"
            onMouseEnter={() => setIsLabelOpen(true)}
            onMouseLeave={() => setIsLabelOpen(false)}
            onClick={() => setIsLabelOpen(true)}
          >
            <Box as={RiQuestionFill} boxSize="24px" color="gray.500" />
          </Text>
        </Tooltip>
      )}
    </Flex>
  )
}

export const InlineInputLabel: React.FC<Props> = ({ subLabel, ...props }) => {
  const subLabelColor = useColorModeValue("gray.400", "gray.300")

  if (!props.label) return null
  return (
    <FormLabel
      lineHeight="shorter"
      htmlFor={props.name}
      fontWeight={400}
      fontSize="sm"
      mb={0}
      mr={2}
      minW={{ base: 70, md: 100 }}
      {...props}
    >
      {props.label}
      {subLabel && (
        <Text fontSize="xs" color={subLabelColor}>
          {subLabel}
        </Text>
      )}
    </FormLabel>
  )
}
