import { useApolloClient } from "@apollo/client"
import { useRouter } from "next/router"

import { ACCESS_TOKEN } from "lib/config"

import { useToast } from "./useToast"

export const useLogout = () => {
  const client = useApolloClient()
  const router = useRouter()
  const toast = useToast()

  const handleLogout = async () => {
    await router.replace("/logout")
    localStorage.removeItem(ACCESS_TOKEN)
    await fetch("/api/logout", { method: "post" })
    await client.resetStore()
    toast({ description: "Succesvol uitgelogd!" })
  }
  return handleLogout
}
