import dayjs from "dayjs"

import { CLOUDINARY_URL } from "../config"

export const isBrowser = typeof window !== "undefined"

export const humanize = (str: string) => {
  return str
    .replace(/^[\s_]+|[\s_]+$/g, "")
    .replace(/[_\s]+/g, " ")
    .replace(/^[a-z]/, function (m) {
      return m.toUpperCase()
    })
}

export const formatFileName = (filename: string): string => {
  const type = filename.split(".").pop()
  let name = filename
    .split(".")[0]
    .toLowerCase()
    .replace(/[^a-z0-9]/g, "-")
  name = dayjs().format("YYYYMMDDHHmmss") + "-" + name
  if (type) {
    name = name + "." + type.toLowerCase()
  }
  return name
}

export function capitalize(word: string): string {
  return word.charAt(0).toUpperCase() + word.substring(1)
}

export function camelToHuman(name?: string | null): string {
  if (!name) return ""
  const words = name.match(/[A-Za-z][a-z]*/g) || []
  return words.map(capitalize).join(" ")
}

export const transformImage = (src?: string | null, transform?: string) => {
  if (!src) return undefined
  if (!transform) return src
  const transforms = "c_fill,q_auto:good,f_auto," + transform
  return CLOUDINARY_URL + "image/fetch/" + transforms + "/" + src
}

export function formatMultiSelectValue(options?: string | null): string {
  if (!options) return ""
  const parsedOptions = JSON.parse(options) as {
    value: string
    label: string
  }[]
  let formatted = ""
  parsedOptions.forEach((option, index) => {
    formatted += option.label
    if (index !== parsedOptions.length - 1) {
      formatted += ", "
    }
  })
  return formatted
}

export const getVideoId = (videoUrl: string): string => {
  const parsedUrl = parseUrlForId(videoUrl)
  const standardAppended = parsedUrl.split("v=")[1]
  const standardAppendedWithChannel = parsedUrl.split("&ab_channel=")[0].split("v=")[1]
  const embedAppended = parsedUrl.split("embed/")[1]
  if (standardAppendedWithChannel) {
    return standardAppendedWithChannel
  } else if (standardAppended) {
    return standardAppended
  } else if (embedAppended) {
    return embedAppended
  }
  return ""
}

export const parseSpotifyURL = (url: string): string => {
  const slicedString = url[url.length - 1] === "/" ? url.slice(0, -1) : url
  const splitUrl = slicedString.split("/")
  const id = splitUrl[splitUrl.length - 1]
  const type = url.includes("album")
    ? "album"
    : url.includes("track")
    ? "track"
    : url.includes("podcast")
    ? "podcast"
    : ""
  return `https://open.spotify.com/oembed?url=spotify:${type}:${id}`
}

export const parseUrl = (url: string): string => {
  url.match(
    /(http:|https:|)\/\/(player.|www.)?(vimeo\.com|youtu(be\.com|\.be|be\.googleapis\.com))\/(video\/|embed\/|watch\?v=|v\/)?([A-Za-z0-9._%-]*)(&\S+)?/,
  )

  let formattedUrl = ""
  if (RegExp.$3.indexOf("youtu") > -1) {
    formattedUrl = `https://www.youtube.com/embed/${RegExp.$6}?modestbranding=1&loop=1&rel=0&amp;controls=0&amp;showinfo=0&playlist=${RegExp.$6}`
  } else if (RegExp.$3.indexOf("vimeo") > -1) {
    formattedUrl = `https://player.vimeo.com/video/${RegExp.$6}?color=000000&title=0&byline=0&portrait=0`
  } else if (url.includes("spotify")) {
    const slicedString = url[url.length - 1] === "/" ? url.slice(0, -1) : url
    const splitUrl = slicedString.split("/")
    const id = splitUrl[splitUrl.length - 1]
    const type = url.includes("album")
      ? "album"
      : url.includes("track")
      ? "track"
      : url.includes("podcast")
      ? "podcast"
      : ""
    formattedUrl = `https://open.spotify.com/embed/${type}/${id}?utm_source=generator`
  } else if (url.includes("mixcloud")) {
    const slicedString = url[url.length - 1] === "/" ? url.slice(0, -1) : url
    const splitUrl = slicedString.split("/")
    const user = splitUrl[splitUrl.length - 2]
    const mix = splitUrl[splitUrl.length - 1]
    formattedUrl = `https://www.mixcloud.com/widget/iframe/?feed=%2F${user}%2F${mix}%2F`
  } else if (url.includes("soundcloud")) {
    formattedUrl = `https://w.soundcloud.com/player/?url=${url}`
  }

  return formattedUrl
}

export const parseUrlForId = (url: string): string => {
  url.match(
    /(http:|https:|)\/\/(player.|www.)?(vimeo\.com|youtu(be\.com|\.be|be\.googleapis\.com))\/(video\/|embed\/|watch\?v=|v\/)?([A-Za-z0-9._%-]*)(&\S+)?/,
  )

  let formattedUrl = ""
  if (RegExp.$3.indexOf("youtu") > -1) {
    formattedUrl = `https://www.youtube.com/embed/${RegExp.$6}`
  } else if (RegExp.$3.indexOf("vimeo") > -1) {
    formattedUrl = `https://player.vimeo.com/video/${RegExp.$6}`
  } else if (url.includes("spotify")) {
    const slicedString = url[url.length - 1] === "/" ? url.slice(0, -1) : url
    const splitUrl = slicedString.split("/")
    const id = splitUrl[splitUrl.length - 1]
    const type = url.includes("album")
      ? "album"
      : url.includes("track")
      ? "track"
      : url.includes("podcast")
      ? "podcast"
      : ""
    formattedUrl = `https://open.spotify.com/embed/${type}/${id}?utm_source=generator`
  } else if (url.includes("mixcloud")) {
    const slicedString = url[url.length - 1] === "/" ? url.slice(0, -1) : url
    const splitUrl = slicedString.split("/")
    const user = splitUrl[splitUrl.length - 2]
    const mix = splitUrl[splitUrl.length - 1]
    formattedUrl = `https://www.mixcloud.com/widget/iframe/?feed=%2F${user}%2F${mix}%2F`
  } else if (url.includes("soundcloud")) {
    formattedUrl = `https://w.soundcloud.com/player/?url=${url}`
  }

  return formattedUrl
}

export const suffix = (i: number) => {
  const j = i % 10,
    k = i % 100
  if (j == 1 && k != 11) {
    return i + "st"
  }
  if (j == 2 && k != 12) {
    return i + "nd"
  }
  if (j == 3 && k != 13) {
    return i + "rd"
  }
  return i + "th"
}

// regex from https://stackoverflow.com/questions/4338267/validate-phone-number-with-javascript
const phoneRegExp = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im

// const phoneRegExp =
//   /^((\\+[1-9]{3,4}[ \\-]*)|(\\([0-9]{2,4}\\)[ \\-]*)|([0-9]{2,5})[ \\-]*)*?[0-9]{1,4}?[ \\-]*[0-9]{1,4}?$/
const emailRegExp =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const messageContainsEmailOrPhone = (message: string | undefined) => {
  return message
    ? message.split(" ").reduce((acc, curr) => {
        if (curr.match(phoneRegExp) || curr.match(emailRegExp) || acc === true) {
          acc = true
          return acc
        }
        return acc
      }, false)
    : false
}
