import * as React from "react"
import { FieldValues, FormProvider, useFormContext, UseFormReturn } from "react-hook-form"
import { Box } from "@chakra-ui/react"
import * as Sentry from "@sentry/nextjs"

import { useToast } from "lib/hooks/useToast"

interface FormContainerProps {
  onSubmit?: (values: any) => Promise<any> | any
  onBlur?: (values: any) => Promise<any> | any
  id?: string
  children: React.ReactNode
}

const FormContainer: React.FC<FormContainerProps> = (props) => {
  const toast = useToast()
  const { handleSubmit } = useFormContext()
  const onSubmit = async (values: any) => {
    try {
      if (props.onBlur) {
        return await props.onBlur(values)
      }
      if (props.onSubmit) {
        return await props.onSubmit(values)
      }
    } catch (e) {
      Sentry.captureException(e)
      toast({ title: "Network error", status: "error" })
      return
    }
  }

  return (
    <Box
      as="form"
      w="100%"
      {...(props.id && { id: props.id })}
      {...(props.onSubmit && { onSubmit: handleSubmit(onSubmit) })}
      {...(props.onBlur && { onBlur: handleSubmit(onSubmit) })}
    >
      {props.children}
    </Box>
  )
}

interface Props<T extends FieldValues> extends UseFormReturn<T>, FormContainerProps {
  children: React.ReactNode
}

export function Form<T extends FieldValues>({ onSubmit, onBlur, id, ...props }: Props<T>) {
  return (
    <FormProvider {...props}>
      <FormContainer {...{ onSubmit, onBlur, id }}>{props.children}</FormContainer>
    </FormProvider>
  )
}
