import * as React from "react"
import { FieldError, useFormContext } from "react-hook-form"
import { Flex, FormControl, Input as CInput, InputProps } from "@chakra-ui/react"

import { InlineInputError, InputError } from "./InputError"
import { InlineInputLabel, InputLabel } from "./InputLabel"

interface Props extends InputProps {
  name: string
  label?: string
  subLabel?: string
  helpText?: string
}

export const Input = ({ label, subLabel, helpText, ...props }: Props) => {
  const {
    register,
    setValue,
    unregister,
    watch,
    formState: { errors },
  } = useFormContext()
  const fieldError = errors?.[props.name] as FieldError | string

  React.useEffect(() => {
    register?.(props.name)
    return () => unregister(props.name)
  }, [props.name, register, unregister])

  const value = watch(props.name) as string | number
  const handleChange = (val: string) => {
    setValue(props.name, props.type === "number" ? Number(val) : val)
  }
  return (
    <FormControl isInvalid={!!fieldError} isRequired={props.isRequired}>
      <InputLabel
        label={label}
        subLabel={subLabel}
        helpText={helpText}
        name={props.name}
        htmlFor={props.name}
      />
      <CInput
        id={props.name}
        aria-label={props.name}
        value={value?.toString() || ""}
        onChange={(e) => handleChange(e.target.value)}
        mb={0}
        {...props}
      />
      <InputError error={fieldError} />
    </FormControl>
  )
}

export const InlineInput = ({ label, subLabel, ...props }: Props) => {
  const {
    register,
    setValue,
    unregister,
    watch,
    formState: { errors },
  } = useFormContext()
  const fieldError = errors?.[props.name] as FieldError | string

  React.useEffect(() => {
    register?.(props.name)
    return () => unregister(props.name)
  }, [props.name, register, unregister])

  const value = watch(props.name) as string | number
  const handleChange = (val: string) => {
    setValue(props.name, props.type === "number" ? Number(val) : val)
  }

  return (
    <FormControl isInvalid={!!fieldError} isRequired={props.isRequired}>
      <Flex align="center">
        <InlineInputLabel label={label} subLabel={subLabel} name={props.name} />
        <CInput
          value={value?.toString() || ""}
          onChange={(e) => handleChange(e.target.value)}
          mb={0}
          {...props}
        />
      </Flex>
      <InlineInputError error={fieldError} />
    </FormControl>
  )
}
